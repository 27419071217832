import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  formatDate,
  getMinValue,
  isValidDeposit,
  Nullable,
} from "../../../utils";
import {
  BookingConfirmation_S as BookingConfirmation,
  BookingSummary_S as BookingSummary,
  ConfirmExtraRequestInput,
  ReservationExtra,
} from "@generated/types";
import { useGenerateResponsiveImageUrl } from "../../../components/Utils";
import { Grid, Hidden, Paper } from "@mui/material";
import { defaultTileWidths, pickValuesAsString } from "../../../utils/common";
import RichText from ".././RichText";
import { BasketSections } from "../../../components/SemanticTheme/FHBasket/BasketSection";
import { ReservationExtra as UIKitReservationExtra } from "../../../interfaces/bookingSummary";
import { PaymentTypes } from "../../../constants/paymentForm";
import { FHBasket } from "../../../components/SemanticTheme/FHBasket";
import {
  removeSpecificCabin,
  removeExtra,
  setRemovedExtraId,
  createConfirmFreeExtras,
} from "@/store/actions";
import {
  useBookingSummaryStateSelector,
  useConfirmedBookingSummaryStateSelector,
  useCreatePaymentStateSelector,
  useSiteSettingsSelector,
} from "@/store/selectors";
import { FhBasketEntity } from "src/graphql/generated-strapi/types";
import { useRouter } from "next/router";

const makeCmsData = (content: any) => ({
  ...pickValuesAsString(content, [
    "BalanceDueLabel",
    "BasketTitle",
    "BedroomLabel",
    "BookingReferenceLabel",
    "CabinUpgradesSectionLabel",
    "ContinueToNextStepCTALabel",
    "DepositLabel",
    "DepositOverrideLabel",
    "DepositTooltip",
    "EditSelectionLabel",
    "ExtrasSectionLabel",
    "IncludedLabel",
    "NoCabinUpgradesLabel",
    "NoExtrasLabel",
    "PromoLabel",
    "PayTodayLabel",
    "PayTodayFromLabel",
    "SpecificCabinLabel",
    "TotalDiscountLabel",
    "TotalPaymentsLabel",
    "TotalPriceLabel",
    "MobileBasketButtonLabel",
    "LowDepositLabel",
    "RemainingDepositLabel",
  ]),
  NoCabinUpgradesDescription:
    content?.["NoCabinUpgradesDescription"] ?? undefined,
  NoExtrasDescription: content?.["NoExtrasDescription"] ?? undefined,
});

export type FHBasketCmsData = ReturnType<typeof makeCmsData>;

export type FHBasketOverrideProps = {
  continueToNextStepCTAOnClick?: () => void;
  defaultExpandedSection?: BasketSections;
  disableContinueCTA?: boolean;
  isConfirmationPage?: boolean;
  isExtrasOnlyPurchaseJourney?: boolean;
  isFHBasketReadOnly?: boolean;
  isPostBookingJourney?: boolean;
  proceedToConfirmationCTALabel?: string;
  isPreBookingPaymentPage?: boolean;
  showAsBasketIconInMobile?: boolean;
  isPostBookingExtrasPage?: boolean;
  isExtrasPaymentPage?: boolean;
  remainingBalanceLabel?: string;
  remainingDepositLabel?: string;
  dueOnLabel?: string;
  overrideCTALabel?: string;
  mobileBasketAddLabel?: boolean;
  applyUnderliningStylingCheckoutCTAs?: boolean;
  showMandatoryExtrasRequiredText?: boolean;
  hideBasketCount?: boolean;
  largerTotalPrice?: boolean;
  requiredDescription?: string;
  experiments?: { [key: string]: string | boolean };
};

interface BasketSummaryProps extends FHBasketOverrideProps {
  attributes: FhBasketEntity;
  overrideProps?: FHBasketOverrideProps;
}

export const FHBasketComponent: React.FC<BasketSummaryProps> = ({
  attributes,
  overrideProps,
}): JSX.Element => {
  const isPostBookingJourney = overrideProps?.isPostBookingJourney || false;
  const isPreBookingPaymentPage =
    overrideProps?.isPreBookingPaymentPage ?? false;
  const isExtrasOnlyPurchaseJourney =
    overrideProps?.isExtrasOnlyPurchaseJourney || false;
  const isExtrasPaymentPage = overrideProps?.isExtrasPaymentPage || false;
  const isConfirmationPage = overrideProps?.isConfirmationPage || false;
  const isPostBookingExtrasOnlyConfirmationPage =
    isPostBookingJourney && isExtrasOnlyPurchaseJourney && isConfirmationPage;
  const isPostBookingExtrasPage =
    overrideProps?.isPostBookingExtrasPage ?? false;
  const hideBasketCount = overrideProps?.hideBasketCount ?? false;
  const isGarwnantLocation = !!overrideProps?.experiments?.breconNaming;
  const paymentsMethodsDefaultUnselect =
    !!overrideProps?.experiments?.paymentsMethodsDefaultUnselect;
  const removeImagesExtrasCabin =
    !!overrideProps?.experiments?.removeImagesExtrasCabinUpgradesBasket;
  const dispatch = useDispatch();
  const history = useRouter();
  const cmsLabels = makeCmsData(attributes?.attributes) as FHBasketCmsData;
  const {
    confirmation: preBookingJourneyConfirmation,
    cabin: preBookingJourneyCabin,
    loading: preBookingJourneyLoading,
    selectedPaymentOption,
    errorMessage,
  } = useBookingSummaryStateSelector();
  const {
    confirmation: postBookingJourneyConfirmation,
    cabin: postBookingJourneyCabin,
    loading: postBookingJourneyLoading,
    obtainingQuoteExtras,
    obtainingQuoteExtrasSellingPrice,
    obtainingQuoteExtrasTotalPrice,
  } = useConfirmedBookingSummaryStateSelector();
  const { errorMessage: paymentError } = useCreatePaymentStateSelector();
  const { isStepBeforeExtrasJourneyPayment } = useSiteSettingsSelector();
  const hideFooter = paymentsMethodsDefaultUnselect
    ? isPreBookingPaymentPage && !!selectedPaymentOption
    : true;

  const extrasAppliedPromoAmount =
    obtainingQuoteExtrasTotalPrice > obtainingQuoteExtrasSellingPrice
      ? obtainingQuoteExtrasTotalPrice - obtainingQuoteExtrasSellingPrice
      : undefined;

  const isPostBookingPage =
    isExtrasOnlyPurchaseJourney || isPostBookingJourney || isConfirmationPage;

  const getCanProceedToConfirmation = () => {
    return (
      Boolean(isStepBeforeExtrasJourneyPayment) &&
      Boolean(isExtrasOnlyPurchaseJourney) &&
      Boolean(isPostBookingJourney) &&
      Boolean(obtainingQuoteExtrasSellingPrice === 0) &&
      Boolean(obtainingQuoteExtras?.length > 0) &&
      Boolean(
        (overrideProps?.proceedToConfirmationCTALabel || "").trim().length > 0,
      ) &&
      !!overrideProps?.continueToNextStepCTAOnClick
    );
  };

  const [confirmation, setConfirmation] = useState<
    Nullable<BookingConfirmation>
  >(
    isPostBookingJourney
      ? postBookingJourneyConfirmation
      : preBookingJourneyConfirmation,
  );
  const [cabin, setCabin] = useState<Nullable<BookingSummary>>(
    isPostBookingJourney ? postBookingJourneyCabin : preBookingJourneyCabin,
  );
  const [loading, setLoading] = useState<boolean>(
    isPostBookingJourney ? postBookingJourneyLoading : preBookingJourneyLoading,
  );
  const [selectedPaymentAmount, setSelectedPaymentAmount] = useState<number>();
  const [canProceedToConfirmation, setCanProceedToConfirmation] =
    useState<boolean>(getCanProceedToConfirmation());
  const [bookingExpired, setBookingExpired] = useState(false);

  const generateImageUrl = (url: string | undefined) => {
    if (!!url) {
      return useGenerateResponsiveImageUrl(
        url,
        defaultTileWidths,
        undefined,
        true,
      );
    }
  };

  const getSpecificCabinNumber = () => {
    let hasSpecificCabinExtra = false;
    if (!!cabin) {
      hasSpecificCabinExtra =
        ((cabin.reservationExtras || []) as ReservationExtra[])
          .flatMap((extra) => extra.reservationExtraOptions || [])
          .findIndex((option) => option?.isSCB) !== -1;
      return hasSpecificCabinExtra && !!cabin.cabinNumber
        ? cabin.cabinNumber.toString()
        : undefined;
    }
    return undefined;
  };

  const handleRemoveExtra = (
    cabinReservationExtraId: string,
    extraId: string,
    isSCB: boolean,
  ) => {
    if (isSCB && !!cabin?.cabinReservationId) {
      dispatch(
        removeSpecificCabin({
          removeSpecificCabinInput: {
            cabinReservationId: cabin?.cabinReservationId,
          },
          isPostBookingJourney: isPostBookingJourney,
        }),
      );
    } else {
      dispatch(
        removeExtra({
          mutationRemoveExtraFromBasketArgs: {
            cabinReservationExtraId: cabinReservationExtraId,
            cabinReservationId: cabin?.cabinReservationId,
          },
          isPostBookingJourney: isPostBookingJourney,
        }),
      );
      dispatch(setRemovedExtraId(extraId));
    }
  };

  useEffect(() => {
    setCanProceedToConfirmation(getCanProceedToConfirmation());
  }, [
    obtainingQuoteExtras,
    obtainingQuoteExtrasSellingPrice,
    isStepBeforeExtrasJourneyPayment,
    isExtrasOnlyPurchaseJourney,
    isPostBookingJourney,
  ]);

  useEffect(() => {
    setConfirmation(
      isPostBookingJourney
        ? postBookingJourneyConfirmation
        : preBookingJourneyConfirmation,
    );
    setCabin(
      isPostBookingJourney ? postBookingJourneyCabin : preBookingJourneyCabin,
    );
    setLoading(
      isPostBookingJourney
        ? postBookingJourneyLoading
        : preBookingJourneyLoading,
    );
  }, [
    preBookingJourneyConfirmation,
    preBookingJourneyCabin,
    preBookingJourneyLoading,
    postBookingJourneyConfirmation,
    postBookingJourneyCabin,
    postBookingJourneyLoading,
  ]);

  const getExtras = (basketSection: BasketSections) => {
    const extras =
      (isExtrasOnlyPurchaseJourney && isPostBookingJourney
        ? obtainingQuoteExtras
        : (cabin?.reservationExtras as UIKitReservationExtra[])) || [];
    const basketSectionExtras = extras.filter(
      (extra) =>
        extra?.isEssentialExtra ===
        (basketSection === BasketSections.cabinUpgrades),
    );
    return basketSectionExtras;
  };

  const handleContinue = () => {
    if (canProceedToConfirmation && !!cabin) {
      const confirmExtraRequestInputMap: ConfirmExtraRequestInput[] =
        obtainingQuoteExtras
          .flatMap((extra) => extra.reservationExtraOptions || [])
          .map((extraOption) => {
            return {
              cabinReservationId: cabin.cabinReservationId,
              cabinReservationExtraId: extraOption?.cabinReservationExtraId,
            } as ConfirmExtraRequestInput;
          });

      dispatch(
        createConfirmFreeExtras({
          request: confirmExtraRequestInputMap,
        }),
      );
    }
    const continueOnClick = overrideProps?.continueToNextStepCTAOnClick;
    if (!!continueOnClick) {
      continueOnClick();
    }
  };

  const getSelectedPaymentValue = (paymentType: string) => {
    if (paymentType === PaymentTypes.instalment) {
      if (
        isValidDeposit(confirmation?.multiPayments?.payInInstalment?.deposit)
      ) {
        return confirmation?.multiPayments?.payInInstalment?.deposit;
      }
    } else if (paymentType === PaymentTypes.deposit) {
      if (isValidDeposit(confirmation?.overrideDeposit)) {
        return confirmation?.overrideDeposit;
      } else if (isValidDeposit(confirmation?.standardDeposit)) {
        return confirmation?.standardDeposit;
      } else {
        return undefined;
      }
    } else if (paymentType === PaymentTypes.full) {
      if (!!confirmation?.balance) {
        return confirmation.balance;
      }
    }
  };

  useEffect(() => {
    if (selectedPaymentOption) {
      setSelectedPaymentAmount(getSelectedPaymentValue(selectedPaymentOption));
    }
  }, [selectedPaymentOption, confirmation]);

  const getDepositAmount = () => {
    if (!!confirmation) {
      const overrideDeposit = isValidDeposit(confirmation.overrideDeposit)
        ? confirmation.overrideDeposit
        : isValidDeposit(confirmation.standardDeposit)
          ? confirmation.standardDeposit
          : null;
      const instalmentDeposit =
        confirmation?.multiPayments?.payInInstalment?.deposit;
      const totalDeposit = confirmation?.totalPrice;

      const depositAmount = [overrideDeposit, instalmentDeposit, totalDeposit];

      return !isPreBookingPaymentPage
        ? getMinValue(depositAmount)
        : selectedPaymentAmount;
    }
  };

  return (
    <>
      {!!cabin && !!confirmation && (
        <Paper>
          <FHBasket
            hideBasketCount={hideBasketCount}
            balanceDueAmount={
              (isPreBookingPaymentPage ||
                (!isExtrasOnlyPurchaseJourney &&
                  !!confirmation.balance &&
                  confirmation.balance > 0)) &&
              !isConfirmationPage
                ? confirmation.balance
                : undefined
            }
            balanceDueDateISO={
              (isPreBookingPaymentPage ||
                (!isExtrasOnlyPurchaseJourney &&
                  moment(confirmation?.balanceDueDate).isAfter(
                    moment().toDate(),
                  ))) &&
              !isConfirmationPage
                ? moment(confirmation?.balanceDueDate)
                    .utc()
                    .format("DD MMMM YYYY")
                : undefined
            }
            remainingDeposit={
              isConfirmationPage &&
              !isPostBookingJourney &&
              !isExtrasOnlyPurchaseJourney &&
              !!confirmation.remainingDepositAmount &&
              confirmation.remainingDepositAmount > 0
                ? confirmation.remainingDepositAmount
                : undefined
            }
            remainingBalance={
              isConfirmationPage &&
              !isPostBookingJourney &&
              !isExtrasOnlyPurchaseJourney
                ? confirmation?.remainingBalance !== null
                  ? confirmation.remainingBalance
                  : !!confirmation.balance && confirmation.balance > 0
                    ? confirmation.balance
                    : undefined
                : undefined
            }
            remainingBalanceDueDate={
              isConfirmationPage &&
              !isPostBookingJourney &&
              !isExtrasOnlyPurchaseJourney &&
              moment(confirmation?.balanceDueDate).isAfter(moment().toDate())
                ? moment(confirmation?.balanceDueDate)
                    .utc()
                    .format("DD MMMM YYYY")
                : undefined
            }
            remainingDepositDueDate={
              isConfirmationPage &&
              !isPostBookingJourney &&
              !isExtrasOnlyPurchaseJourney &&
              moment(confirmation?.remainingDepositDate).isAfter(
                moment().toDate(),
              )
                ? moment(confirmation?.remainingDepositDate)
                    .utc()
                    .format("DD MMMM YYYY")
                : undefined
            }
            balanceDueLabel={cmsLabels?.BalanceDueLabel}
            bedroomLabel={cmsLabels?.BedroomLabel}
            bookingImageUrl={
              !!cabin?.cabinTypeImages && cabin?.cabinTypeImages[0]?.url
                ? generateImageUrl(cabin?.cabinTypeImages[0].url)
                : undefined
            }
            bookingReference={confirmation?.bookingReference}
            bookingReferenceLabel={cmsLabels?.BookingReferenceLabel}
            cabinSellingPrice={
              !isExtrasOnlyPurchaseJourney ? cabin?.sellingPrice : undefined
            }
            cabinType={cabin?.cabinTypeName || undefined}
            cabinUpgrades={getExtras(BasketSections.cabinUpgrades)}
            cabinUpgradesSectionLabel={cmsLabels?.CabinUpgradesSectionLabel}
            continueToNextStepCTALabel={
              !!overrideProps?.overrideCTALabel
                ? overrideProps.overrideCTALabel
                : canProceedToConfirmation
                  ? overrideProps?.proceedToConfirmationCTALabel
                  : cmsLabels?.ContinueToNextStepCTALabel
            }
            continueToNextStepCTAOnClick={
              !!overrideProps?.continueToNextStepCTAOnClick
                ? handleContinue
                : undefined
            }
            disableContinueCTA={overrideProps?.disableContinueCTA}
            showMandatoryExtrasRequiredText={
              overrideProps?.showMandatoryExtrasRequiredText
            }
            defaultExpandedSection={overrideProps?.defaultExpandedSection}
            depositAmount={
              !(
                (confirmation?.standardDeposit || 0) > 0 ||
                (confirmation?.overrideDeposit || 0) > 0
              ) ||
              (isPostBookingPage && !isExtrasPaymentPage)
                ? undefined
                : isExtrasPaymentPage
                  ? (extrasAppliedPromoAmount &&
                      obtainingQuoteExtrasSellingPrice) ||
                    undefined
                  : getDepositAmount()
            }
            depositLabel={
              isPostBookingPage && !isExtrasPaymentPage
                ? undefined
                : isPreBookingPaymentPage || isExtrasPaymentPage
                  ? cmsLabels?.PayTodayLabel
                  : cmsLabels?.PayTodayFromLabel
            }
            depositOverrideLiableAmount={
              isPostBookingPage
                ? undefined
                : (isPreBookingPaymentPage &&
                      selectedPaymentOption === PaymentTypes.deposit &&
                      !!confirmation?.overrideDeposit) ||
                    (!isPreBookingPaymentPage &&
                      !!confirmation?.overrideDeposit)
                  ? (confirmation?.standardDeposit ?? 0) -
                    confirmation?.overrideDeposit
                  : undefined
            }
            depositTooltip={
              isValidDeposit(confirmation?.overrideDeposit)
                ? cmsLabels?.DepositTooltip
                : undefined
            }
            endDate={formatDate(cabin?.reservationEndDate)}
            excludeCabinInBasketTotalItems={isPostBookingJourney}
            extras={getExtras(BasketSections.extras)}
            extrasSectionLabel={cmsLabels?.ExtrasSectionLabel}
            includedLabel={cmsLabels?.IncludedLabel}
            isFHBasketReadOnly={overrideProps?.isFHBasketReadOnly}
            isPetFriendlyCabin={cabin?.petFriendly as boolean}
            loading={loading}
            locationName={
              isGarwnantLocation &&
              cabin?.locationName?.toLowerCase() === "garwnant"
                ? "Brecon Beacons"
                : cabin?.locationName || undefined
            }
            noCabinUpgradesDescription={
              <RichText
                contentId="text"
                contentValue={cmsLabels.NoCabinUpgradesDescription}
              />
            }
            noCabinUpgradesLabel={cmsLabels?.NoCabinUpgradesLabel}
            noExtrasDescription={
              <RichText
                contentId="text"
                contentValue={cmsLabels?.NoExtrasDescription}
              />
            }
            noExtrasLabel={cmsLabels.NoExtrasLabel}
            onRemoveExtra={(cabinReservationExtraId, extraId, isSCB) =>
              handleRemoveExtra(cabinReservationExtraId, extraId, isSCB)
            }
            numberOfBedrooms={cabin?.noOfBedrooms?.toString()}
            paymentAmount={
              !isExtrasOnlyPurchaseJourney
                ? confirmation?.totalPayments
                : isPostBookingExtrasOnlyConfirmationPage
                  ? obtainingQuoteExtrasSellingPrice
                  : undefined
            }
            preDiscountCabinSellingPrice={cabin?.preDiscountCabinPrice}
            preDiscountTotalAmount={
              isExtrasOnlyPurchaseJourney && isPostBookingJourney
                ? obtainingQuoteExtrasTotalPrice
                : confirmation?.preDiscountTotalPrice
            }
            promoAmount={
              !isExtrasOnlyPurchaseJourney
                ? confirmation?.totalDiscountAmount
                : isExtrasPaymentPage
                  ? extrasAppliedPromoAmount
                  : undefined
            }
            promoLabel={cmsLabels?.PromoLabel}
            showAsBasketIconInMobile={overrideProps?.showAsBasketIconInMobile}
            specificCabinLabel={cmsLabels?.SpecificCabinLabel}
            specificCabinNumber={getSpecificCabinNumber()}
            startDate={formatDate(
              cabin?.reservationStartDate,
              !moment(cabin?.reservationEndDate).isSame(
                cabin?.reservationStartDate,
                "year",
              ),
            )}
            totalAmount={
              isExtrasOnlyPurchaseJourney && isPostBookingJourney
                ? obtainingQuoteExtrasSellingPrice
                : confirmation?.totalPrice
            }
            totalDiscountLabel={cmsLabels?.TotalDiscountLabel}
            totalPaymentsLabel={cmsLabels?.TotalPaymentsLabel}
            totalPriceLabel={cmsLabels?.TotalPriceLabel}
            mobileBasketButtonLabel={
              isPostBookingExtrasPage
                ? cmsLabels.MobileBasketButtonLabel
                : undefined
            }
            remainingDepositLabel={
              isConfirmationPage
                ? overrideProps?.remainingDepositLabel
                : cmsLabels?.LowDepositLabel
            }
            remainingBalanceLabel={
              isConfirmationPage
                ? overrideProps?.remainingBalanceLabel
                : cmsLabels?.RemainingDepositLabel
            }
            dueOnLabel={overrideProps?.dueOnLabel}
            mobileBasketAddLabel={overrideProps?.mobileBasketAddLabel}
            applyUnderliningStylingCheckoutCTAs={
              overrideProps?.applyUnderliningStylingCheckoutCTAs
            }
            isPostBookingJourney={isPostBookingJourney}
            largerTotalPrice={!!overrideProps?.largerTotalPrice}
            requiredDescription={overrideProps?.requiredDescription}
            hideFooter={hideFooter}
            removeImagesExtrasCabin={removeImagesExtrasCabin}
            addExtrasInOneSection={
              !!overrideProps?.experiments?.addExtrasInOneSection
            }
          />
        </Paper>
      )}
    </>
  );
};
